<template>
  <cui-a-c-l :roles="[1]" redirect>
    <div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <div class="cui__utils__heading mb-0">
                <strong>Create User</strong>
              </div>
              <div class="text-muted">Create new user under</div>
            </div>
            <div class="card-body">
              <a-form :form="form" @submit.prevent="handleSubmit">
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="First Name">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>User's first name.</span>
                    </template>
                    <a-input placeholder="User First Name" v-decorator="[ 'firstName', {
                  rules: [
                    { required: true, message: 'First Name is required.' },
                    { max: 32, message: 'Maximum 32 characters allowed.'}
                    ]}]"/>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Last Name">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>User's last name.</span>
                    </template>
                    <a-input placeholder="User Last Name" v-decorator="[ 'lastName', {
                  rules: [
                    { required: true, message: 'Last Name is required.' },
                    { max: 32, message: 'Maximum 32 characters allowed.'}
                    ]}]"/>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Email">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>User's e-mail address.</span>
                    </template>
                    <a-input type="email" placeholder="User Email Address" v-decorator="[ 'email', {
                  rules: [
                    { required: true, message: 'Email is required.', pattern: emailPattern },
                    { max: 64, message: 'Maximum 64 characters allowed.'}
                    ]}]"/>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Password">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>User's password.</span>
                    </template>
                    <a-input type="password" placeholder="User Password" v-decorator="[ 'password', {
                  rules: [
                    { required: true, message: 'Password is required.' },
                    { max: 64, message: 'Maximum 64 characters allowed.'}
                    ]}]"/>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Country">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>User's country.</span>
                    </template>
                    <a-select
                      v-decorator="[ 'country', {rules: [{ required: true, message: 'Country is required.'}]} ]"
                      placeholder="Please select a country"
                      :showSearch="true"
                      :filterOption="true"
                      optionFilterProp="children"
                    >
                      <a-select-option v-for="obj in info.countries" :key="obj.CC">{{ obj.Country }}</a-select-option>
                    </a-select>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="City">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>User's city.</span>
                    </template>
                    <a-input placeholder="City" v-decorator="[ 'city', { initialValue: null,
                 rules: [
                    { max: 20, message: 'Maximum 20 characters allowed.'}
                    ]}]"/>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Zip Code">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>User's zip code.</span>
                    </template>
                    <a-input type="number" placeholder="Zip Code" v-decorator="[ 'zipCode', { initialValue: null,
                rules: [
                  {transform: function(value) { return Number(value)}, type: 'number'}
                  ]}]"/>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Address">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>User's address.</span>
                    </template>
                    <a-input placeholder="Address" v-decorator="[ 'address', { initialValue: null,
                 rules: [
                    { max: 64, message: 'Maximum 64 characters allowed.'}
                    ]}]"/>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Phone">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>User's phone number.</span>
                    </template>
                    <a-input placeholder="Phone" v-decorator="[ 'phone', { initialValue: null,
                 rules: [
                   { message: 'Invalid phone number.' },
                   { max: 20, message: 'Maximum 20 characters allowed.'}
                   ]}]"/>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Skype">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>User's Skype username.</span>
                    </template>
                    <a-input placeholder="Skype" v-decorator="[ 'skype', { initialValue: null,
                 rules: [
                   { max: 32, message: 'Maximum 32 characters allowed.'}
                   ]}]"/>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Admin">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Should user have Administrator role?</span>
                    </template>
                    <a-checkbox :checked="role" v-decorator="[ 'role' ]" :default-checked="false"
                                @change="role = !role">
                    </a-checkbox>
                  </a-tooltip>
                </a-form-item>
                <div class="ant-row ant-form-item">
                <a-divider class="mt-0"></a-divider>
                  <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                  <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                    <button type="submit" class="btn btn-success px-5">Create User</button>
                    <button type="button" @click="$router.push('/users')" class="btn btn-light px-5 ml-3">Cancel
                    </button>
                  </div>
                </div>
              </a-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </cui-a-c-l>
</template>

<script>
import { mapState } from 'vuex'
import CuiACL from '@/components/cleanui/system/ACL/index'

export default {
  components: {
    CuiACL,
  },
  computed: {
    ...mapState(['info']),
  },
  data() {
    return {
      role: false,
      emailPattern: '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])',
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    }
  },
  methods: {
    handleSubmit() {
      const instance = this
      this.form.validateFields((err, values) => {
        if (!err) {
          values.role = instance.role ? 1 : 0
          this.$store.dispatch('user/CREATE_USER', {
            payload: values,
            callback: function () {
              this.$router.push({ name: 'users' }).catch(() => {
              })
            }.bind(this),
          })
        }
      })
    },
  },
  created() {
    this.$store.dispatch('info/LOAD_COUNTRIES')
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}
</style>
